<template>
  <div class="gzYxpj">
    <div class="gzYxpj-top-h1">运行评价</div>
    <div class="gzYxpj-top">
      <div class="gzYxpj-top-tit flex">
        <div class="cell cell1">评价类型</div>
        <div class="cell">自控率</div>
        <div class="cell">稳定性</div>
        <div class="cell">经济性</div>
        <div class="cell">综合评价</div>
        <div class="cell">旋风热风温</div>
        <div class="cell">旋风床温</div>
        <div class="cell">旋风床温合格</div>
        <div class="cell">流化床水温</div>
        <div class="cell">流化床床温</div>
        <div class="cell">流化床床温合格</div>
      </div>
      <div class="gzYxpj-top-item flex" v-for="item in itemList" :key="item.id">
        <div class="cell cell1">{{ item.name }}</div>
        <div
          class="cell shou"
          v-for="(childItem, childIndex) in item.arr"
          :key="childIndex"
          @click="
            toCompon(
              2,
              `ANEV${item.id}0${childItem}`,
              'DB1V',
              `ANEV${item.id}0${childItem}_GZQ`,
              '历史趋势'
            )
          "
          @dblclick="
            Cclick(
              infoList.DB1V[`ANEV${item.id}0${childItem}`],
              `ANEV${item.id}0${childItem}`,
              'DB1V',
              `ANEV${item.id}0${childItem}_GZQ`
            )
          "
        >
          {{ infoList.DB1V[`ANEV${item.id}0${childItem}`] || "-" }}
        </div>
      </div>
    </div>
    <div class="gzYxpj-b flex">
      <div class="gzYxpj-b-1">
        <div class="gzYxpj-top-h1">系统时钟设定</div>
        <div class="gzYxpj-b-1-item">
          <div class="cell cell1 flex">
            <div class="cell-name">名称</div>
            <div class="cell-num">状态与设定</div>
          </div>
          <div class="cell flex">
            <div class="cell-name">控制器时钟:</div>
            <div class="cell-num">
              {{ infoList.DB1V.ANSYSYEAR || "1900" }}年{{
                infoList.DB1V.ANSYSMON || "1"
              }}月{{ infoList.DB1V.ANSYSDAY || "1" }}日
              {{ infoList.DB1V.ANSYSHOUR || "00" }} :
              {{ infoList.DB1V.ANSYSMIN || "00" }}
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">交班时间1设定:</div>
            <div class="cell-num">
              {{ infoList.DB1V.ANSYS01 || "00" }} :
              {{ infoList.DB1V.ANSYS02 || "00" }}
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">交班时间2设定:</div>
            <div class="cell-num">
              {{ infoList.DB1V.ANSYS03 || "00" }} :
              {{ infoList.DB1V.ANSYS04 || "00" }}
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">交班时间3设定:</div>
            <div class="cell-num">
              {{ infoList.DB1V.ANSYS05 || "00" }} :
              {{ infoList.DB1V.ANSYS06 || "00" }}
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">日复位时间设定:</div>
            <div class="cell-num">
              每日 {{ infoList.DB1V.ANSYS09 || "00" }} :
              {{ infoList.DB1V.ANSYS10 || "00" }}
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">月复位时间设定:</div>
            <div class="cell-num">
              每月 {{ infoList.DB1V.ANSYS1 || "01" }}日
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">设定时长:</div>
            <div class="cell-num flex flex">
              <span style="color: #00e4ff">{{
                infoList.DB1V.ANEV501 || "0"
              }}</span>
              <div class="btn" :style="infoList.DB1V.DGEVKS | bgFillter">
                {{ infoList.DB1V.DGEVKS ? "开始考核" : "考核结束" }}
              </div>
            </div>
          </div>
          <div class="cell flex">
            <div class="cell-name">已考核时长:</div>
            <div class="cell-num">{{ infoList.DB1V.ANEV501 || "0.0" }}</div>
          </div>
          <div class="cell flex">
            <div class="cell-name">开始时间:</div>
            <div class="cell-num">######</div>
          </div>
          <div class="cell flex">
            <div class="cell-name">结束时间:</div>
            <div class="cell-num">######</div>
          </div>
          <div class="cell cell2 flex">
            <div class="cell-name"></div>
            <div class="cell-num"></div>
          </div>
        </div>
      </div>
      <div class="gzYxpj-b-1 gzYxpj-b-2">
        <div class="gzYxpj-top-h1">运行评价参数</div>
        <div class="gzYxpj-b-1-item">
          <div class="cell cell1 flex">
            <div class="cell-name">参数名称</div>
            <div class="cell-stu">状态</div>
            <div class="cell-num">精度设定</div>
          </div>
          <div
            class="cell flex"
            v-for="(item, index) in itemList1"
            :key="index"
          >
            <div class="cell-name">{{ item.name }}</div>
            <div class="cell-stu">
              <span :style="infoList.DB1V[`DGEV${item.id}`] | bgFillter">
                {{ infoList.DB1V[`DGEV${item.id}`] | textFillter }}
              </span>
            </div>
            <div class="cell-num">
              {{ infoList.DB1V[`ANEV${item.id}`] || "0" }}
            </div>
          </div>
        </div>
      </div>
      <div class="gzYxpj-b-1 gzYxpj-b-3">
        <div class="gzYxpj-top-h1">自控率统计</div>
        <div class="gzYxpj-b-1-item">
          <div class="cell cell1 flex">
            <div class="cell-name">回路名称</div>
            <div class="cell-stu">参与统计</div>
            <div class="cell-stu1">投运状态</div>
            <div class="cell-num">本班自控率</div>
          </div>
          <div
            class="cell flex"
            v-for="(item, index) in itemList2"
            :key="index"
          >
            <div class="cell-name">{{ item.name }}</div>
            <div class="cell-stu">
              <span :style="infoList.DB1V[`DGZKL${item.id}`] | bgFillter">
                {{ infoList.DB1V[`DGZKL${item.id}`] | textFillter }}
              </span>
            </div>
            <div class="cell-stu1">
              <span
                :style="
                  (infoList.DB1S[`SW${item.isnode1}`] ||
                    infoList.DB1S[`SW${item.isnode2}`]) | bgFillter
                "
                v-if="item.id != '10'"
              >
                {{
                  (infoList.DB1S[`SW${item.isnode1}`] ||
                    infoList.DB1S[`SW${item.isnode2}`]) | text1Fillter
                }}
              </span>
              <span style="background: red">手动</span>
            </div>
            <div class="cell-num">
              {{ infoList.DB1V[`ANZKL${item.id}`] || "0" }}
            </div>
          </div>
          <div class="cell flex cell2">
            <div class="cell-name">瞬时自控率</div>
            <div class="cell-name1">{{ infoList.DB1V.ANZKL00 || "0" }}</div>
          </div>
        </div>
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>
<script>
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "gzYxpj",
  components: {
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data() {
    return {
      chName: "",
      isComShow: false,
      isHshow: false,
      isClose: false,
      historyname: "",
      node: "",
      Lkname: "",
      spotArr: [],
      grouptime: null,
      itemList: [
        {
          id: 1,
          name: "班运行",
          arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        {
          id: 2,
          name: "日运行",
          arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        {
          id: 3,
          name: "月运行",
          arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
        {
          id: 4,
          name: "自定义运行",
          arr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
      ],
      itemList1: [
        {
          id: "01",
          name: "旋风床进料",
        },
        {
          id: "02",
          name: "流化床进料",
        },
        {
          id: "03",
          name: "旋风床蒸汽",
        },
        {
          id: "04",
          name: "流化床水温",
        },
        {
          id: "05",
          name: "混料风量",
        },
        {
          id: "06",
          name: "活塞风量",
        },
        {
          id: "07",
          name: "流化床风温",
        },
        {
          id: "08",
          name: "出料",
        },
        {
          id: "09",
          name: "旋风床风压",
        },
        {
          id: "10",
          name: "备用10",
        },
        {
          id: "11",
          name: "备用11",
        },
      ],
      itemList2: [
        {
          id: "01",
          name: "旋风床进料",
          isnode1: "01",
          isnode2: "02",
        },
        {
          id: "02",
          name: "流化床进料",
          isnode1: "03",
          isnode2: "04",
        },
        {
          id: "03",
          name: "旋风床蒸汽",
          isnode1: "06",
          isnode2: "00",
        },
        {
          id: "04",
          name: "流化床水温",
          isnode1: "07",
          isnode2: "00",
        },
        {
          id: "05",
          name: "混料风量",
          isnode1: "09",
          isnode2: "00",
        },
        {
          id: "06",
          name: "活塞风量",
          isnode1: "10",
          isnode2: "00",
        },
        {
          id: "07",
          name: "流化床风温",
          isnode1: "11",
          isnode2: "00",
        },
        {
          id: "08",
          name: "出料",
          isnode1: "12",
          isnode2: "00",
        },
        {
          id: "09",
          name: "旋风床风压",
          isnode1: "13",
          isnode2: "00",
        },
        {
          id: "10",
          name: "回路10",
          isnode1: "00",
          isnode2: "00",
        },
      ],
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
  },
  methods: {
    //
    openTip() {
      this.$message.error("请返回通讯总览页面执行操作");
    },
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, name4) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (this.isMshow = true);
          case 1:
            return (this.isFshow = true);
          case 2:
            return (this.isHshow = true), (this.chName = name4);
        }
      }, 300);
    },
  },
  filters: {
    //        颜色过滤器
    bgFillter(val) {
      if (val) {
        return "background:#0de0ac";
      } else {
        return "background:red";
      }
    },
    // 文字过滤器
    textFillter: (val) => {
      if (val) {
        return "参与";
      } else {
        return "不参与";
      }
    },
    text1Fillter: (val) => {
      if (val) {
        return "自动";
      } else {
        return "手动";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.gzYxpj {
  position: relative;
  .gzYxpj-top-h1 {
    margin-top: 3vh;
    font-size: 1.5vw;
    color: #fff;
    text-align: center;
  }
  .gzYxpj-top {
    margin: 0 auto;
    margin-top: 1vh;
    height: 18vh;
    width: 95vw;
    border: 1px solid rgba(0, 228, 255, 0.2);
    border-bottom: none;
    .gzYxpj-top-tit {
      height: 3.6vh;
      width: 100%;
      .cell {
        color: #458ca4;
        font-size: 0.9vw;
        line-height: 3.6vh;
        width: 8.5vw;
        box-sizing: border-box;
        text-align: center;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .cell:last-child {
        border-right: none;
      }
      .cell1 {
        width: 10vw;
      }
    }
    .gzYxpj-top-item {
      height: 3.6vh;
      width: 100%;
      .cell {
        color: #fff;
        font-size: 0.9vw;
        line-height: 3.6vh;
        width: 8.5vw;
        box-sizing: border-box;
        text-align: center;
        border-bottom: 1px solid rgba(0, 228, 255, 0.2);
        border-right: 1px solid rgba(0, 228, 255, 0.2);
      }
      .cell:last-child {
        border-right: none;
      }
      .cell1 {
        width: 10vw;
        color: #8aeaff;
      }
    }
  }
  .gzYxpj-b {
    position: relative;
    .gzYxpj-b-1 {
      width: 28vw;
      text-align: center;
      margin-left: 2.5vw;
      .gzYxpj-top-h1 {
        width: 28vw;
        margin-top: 3vh;
        font-size: 1.5vw;
        color: #fff;
        text-align: center;
      }
      .gzYxpj-b-1-item {
        width: 28vw;
        border: 1px solid rgba(0, 228, 255, 0.2);
        height: 38vh;
        margin-top: 1vh;
        .cell {
          width: 100%;
          height: 3.2vh;
          line-height: 3.2vh;
          font-size: 0.9vw;
          border-bottom: 1px solid rgba(0, 228, 255, 0.2);
          box-sizing: border-box;
          .cell-name {
            color: #8aeaff;
            border-right: 1px solid rgba(0, 228, 255, 0.2);
            width: 10vw;
          }
          .cell-num {
            color: #fff;
            width: 18vw;
            justify-content: space-around;
            align-items: center;
            .btn {
              width: 5vw;
              height: 3vh;
              line-height: 3vh;
              text-align: center;
              color: #fff;
            }
          }
        }
        .cell1 {
          height: 2.8vh;
          line-height: 2.8vh;
          .cell-num {
            color: #8aeaff;
          }
        }
        .cell2 {
          border-bottom: none;
        }
      }
    }
    .gzYxpj-b-2 {
      width: 34vw;
      text-align: center;
      margin-left: 2vw;
      .gzYxpj-top-h1 {
        width: 34vw;
        margin-left: 0;
      }
      .gzYxpj-b-1-item {
        width: 34vw;
        margin-left: 0;
        border-bottom: none;
        .cell {
          .cell-stu {
            width: 12vw !important;
            border-right: 1px solid rgba(0, 228, 255, 0.2);
            color: #8aeaff;
            span {
              width: 5vw;
              height: 3vh;
              line-height: 3vh;
              display: block;
              color: #fff;
              text-align: center;
              margin: 0 auto;
              margin-top: 0.1vh;
            }
          }
          .cell-num {
            width: 12vw !important;
            color: #00e4ff;
          }
        }
        .cell1 {
          .cell-stu {
            color: #458ca4;
          }
          .cell-num {
            color: #458ca4;
          }
          .cell-name {
            color: #458ca4;
          }
        }
      }
    }
    .gzYxpj-b-3 {
      width: 29vw;
      text-align: center;
      margin-left: 2vw;
      .gzYxpj-top-h1 {
        width: 29vw;
        margin-left: 0;
      }
      .gzYxpj-b-1-item {
        .cell {
          .cell-stu,
          .cell-stu1 {
            width: 6vw;
            border-right: 1px solid rgba(0, 228, 255, 0.2);
            span {
              width: 5vw;
              height: 3vh;
              line-height: 3vh;
              display: block;
              color: #fff;
              text-align: center;
              margin: 0 auto;
              margin-top: 0.1vh;
            }
          }
          .cell-num {
            width: 7vw !important;
          }
          .cell2 {
            .cell-name {
              box-sizing: border-box;
            }
          }
          .cell-name1 {
            width: calc(19vw + 1px);
            color: #fff;
          }
        }
        .cell1 {
          .cell-name {
            color: #458ca4;
          }
          .cell-stu,
          .cell-stu1 {
            color: #458ca4;
          }
          .cell-num {
            color: #458ca4;
          }
        }
      }
    }
  } .shou{
        cursor: pointer;
      }
}
</style>